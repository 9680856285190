import * as React from 'react'
import styled from '@emotion/styled'
import { colors, fonts } from '../../styles/variables'
import ContextConsumer from '../Context'
import LocalizedLink from '../links/LocalizedLink'
import { LilaEye } from '../../constants/lilaIcons'
import PaypalExpress from '../../gateways/PaypalExpress'
import Ripples from 'react-ripples'
import { FormattedMessage } from 'react-intl';
import Fade from 'react-reveal/Fade';
import Popup from "reactjs-popup"
import Select, {components} from 'react-select'
import { ElementConfig } from 'react'
import { BsChevronCompactDown } from 'react-icons/bs'
import {
  LilaIconWrap, LilaPaymentMethod,
  LilaSvgContainer
} from '../../constants/paymentMethodIcons'
import chroma from 'chroma-js';
import {
  DHLIcon,
  DPDIcon,
  FedExIcon,
  GLSIcon,
  HermesIcon,
  UPSIcon
} from '../../constants/shippingSolutionIcons'
import {
  dhlExpress_eu, dpdExpress_eu,
  express_shipping_de,
  express_shipping_gb, fedexExpress_eu, glsExpress_eu,
  standard_shipping_de,
  standard_shipping_gb, upsExpress_eu
} from '../../constants/shippingOptions'
import { lilaRegions } from '../../constants/lilaRegions'





const LilaArrowWrap = styled.div`
width:54px;
display:flex;
justify-content:center;
align-items:center;
`






const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
      <LilaArrowWrap><BsChevronCompactDown /></LilaArrowWrap>

    </components.DropdownIndicator>
  );
};
const colourOptions = [
  { title:'UPS', value: 0,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><UPSIcon/></LilaIconWrap><LilaPaymentMethod>UPS</LilaPaymentMethod></LilaSvgContainer> },
  { title:'Hermes',value: 1,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><HermesIcon/></LilaIconWrap><LilaPaymentMethod>Hermes</LilaPaymentMethod></LilaSvgContainer> },
  {title:'GLS', value: 2, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><GLSIcon/></LilaIconWrap><LilaPaymentMethod>GLS</LilaPaymentMethod></LilaSvgContainer>},
  { title:'DHL', value: 3, color:'darkgrey',label: <LilaSvgContainer><LilaIconWrap><DHLIcon/></LilaIconWrap><LilaPaymentMethod>DHL</LilaPaymentMethod></LilaSvgContainer> },
  {title:'dpd', value: 4, color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><DPDIcon/></LilaIconWrap><LilaPaymentMethod>dpd</LilaPaymentMethod></LilaSvgContainer> },
  { title:'FedEx',value: 5,color:'darkgrey', label: <LilaSvgContainer><LilaIconWrap><FedExIcon/></LilaIconWrap><LilaPaymentMethod>FedEx</LilaPaymentMethod></LilaSvgContainer>},
]



const colourStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      marginBottom: '18px',
      marginTop:'18px',
      opacity:!state.isFocused ? .2: 1,
      color:'var(--txt)'

    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      paddingLeft:'1rem'

    };
  },
  menu: styles => ({ ...styles,  zIndex: 999999999999}),


  control: (styles, state) => ({
    ...styles,
    '&::after': { borderColor: 'var(--hase)' },
    '&:hover': { borderColor: 'none', backgroundColor:'rgba(0, 0, 0, 0.06)' },
    borderColor:!state.isFocused ?'var(--border)':'var(--hase)',
    boxShadow: 'none',
    borderWidth:!state.isFocused ?'1px':'2px',
    borderTop:!state.isFocused ?'1px':'0px',
    borderLeft:!state.isFocused ?'1px':'0px',
    borderRight:!state.isFocused ?'1px':'0px',
    background:'rgba(0, 0, 0, 0.03)',
    color:'var(--txt) !important',
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px',
    borderBottomRightRadius:'0px',
    borderBottomLeftRadius:'0px',
    height:'56px',
  }),

  singleValue: styles => ({ ...styles, backgroundColor: 'none',

    color:'var(--txt) !important' }),
  placeholder: styles => ({ ...styles, fontFamily: fonts.bcHairLine,

    color:'var(--txt) !important' }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,

      backgroundColor: isDisabled
        ? 'var(--bg)'
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : 'var(--selectBg)',
      color: isDisabled
        ? 'var(--txt)'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const LilaHase_2 = styled.div`
width:100%;
display:flex;
align-items:center;
height:50px;




`

const StyledPopup:any = styled(Popup)`

`

const PopIcon = styled.div`
width:10px;

text-align:center;
color:var(--txt);

`

const LilaPopup = styled.div`


position:relative;
z-index:999999999;
.modal > .close {

  cursor: pointer;
  position: absolute;
  display: flex;
  line-height: 20px;
  right: 14px;
  top: 14px;
  font-size: 24px;
  background: var(--bw);
  border-radius: 50%;
    border: 1px solid var(--background);
    z-index:999999999  !important;
width:36px;
height:36px;
justify-content:center;
align-items:center;

}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  //border-radius: 2px;
  font-weight: 600;
  text-transform: uppercase;
  //letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

form {
margin-top:40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
  width:400px;

  @media screen and (max-width:960px){
width:320px;
  margin-left: auto;
margin-right: auto;
  }

}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 2px;
  background: white;
}

input {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 2px;
  background: white;
  border:none;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.modal {

min-height:200px;
width:100%;
padding-left:76px;
padding-top:76px;
  @media screen and (max-width:960px){
  width:100%;
  }

  @media screen and (max-width:627px){
padding-left:5%;
}
}

.popup-content {
background:var(--bg) !important;
width:80% !important;

display:flex;
justify-content:center;
align-items:center;
border:none !important;
padding:0px !important;
border-radius:2px;

@media screen and (max-width:760px){
width:100% !important;
}

}

.popup-overlay{
z-index:999999 !important;
}

.CardDemo{
  @media screen and (max-width:960px){
  width:100% !important;
  }
}

.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}

`




const Lh_2 = styled.div`
width:226px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`

const LilaSymbol = styled.div`
.cls-1{fill:var(--hase);}

width:64px;
margin-left:7px;

.rotate {
  animation: rotation 7s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67);
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`

const Gutschrift = styled.div`
margin-top:0.7rem;
font-family:${fonts.bcExtraLight};
font-size:1.5rem;
`

const LilaRowsWrap = styled.div`
width:100%;
display:flex;
margin-bottom:3rem;
margin-top:1rem;
`

const LilaRowsWrap_2 = styled.div`
width:100%;
display:flex;
margin-bottom:5rem;
margin-top:1rem;
`

const GutschriftSub = styled.div`
font-family:${fonts.bcExtraLight};
font-size:1rem;
padding-left:2px;

`
const GutschriftSub_2 = styled.div`
font-family:${fonts.bcExtraLight};
font-size:0.8rem;
padding-left:2px;

`




const LilaMobileOverview = styled.div`
position:relative;

width:100%;




`


const LilaEntry = styled.div`
margin-right:1rem;
font-family:${fonts.bcHairLine};


`

const OverviewHeader = styled.div`
height:50px;
display:flex;
align-items:center;
width:100%;



`




const LilaFoot = styled.div`

font-family:${fonts.bcBook};
margin-bottom:2rem;
`



























const Div = styled.div`
display:block !important;
padding-top:5px;
padding-bottom:5px
`


const LilaTotal = styled.div`
margin-top:0.44rem;
font-size: 0.75rem;
width:100%;
padding-left:18px;
display:flex;

`
const LilaSubTotal = styled.div`
margin-top:2rem;
margin-bottom:0.44rem;

font-size: 0.75rem;
width:100%;
padding-left:18px;
display:flex;
padding-right:18px;
`
const LilaShipping = styled.div`
margin-bottom:0.44rem;

font-size: 0.75rem;
width:100%;
padding-left:18px;
display:flex;
`

const LilaTaxInfo = styled.div`
font-size:0.6rem;
letter-spacing:1px;
opacity:.3;
font-size: 0.65rem;
display:flex;
margin-left:4.3rem;

`

const LilaInclusive = styled.div`
display:flex;
`

const LilaExpress = styled.div`
justify-content:center !important;
//padding-left:18px;

`

const LilaGutschrift = styled.div`
justify-content:flex-start !important;
padding-left:18px;
padding-top:4px;
padding-bottom:4px;
background:var(--bw);
font-size: 0.75rem;
display:flex;
align-items:center;


`
const LilaGutschriftDetails = styled.div`
align-items:center;
display:flex;
margin-left:0.3rem;
cursor:pointer;
margin-bottom:0.189rem;



`
const StyledLilaEye = styled.div`
width:20px;
height:13px;
margin-left:0.3rem;
color:var(--hase);
  .cls-1 {
        fill: var(--icon2);
      }
       .cls-2 {
        fill: var(--icon2);
        transition:all .3s ease;
      }

      :hover{
  .cls-2 {
        fill: var(--hase);
      }
      }
`


const LilaRows = styled.div`
margin-left:1rem;


`

const LilaHairline = styled.div`
display:flex;
font-family:${fonts.bcHairLine};
`
const LilaEuro = styled.div`
width:60px;
display:flex;
justify-content:flex-end;
`

const LilaEuro_2 = styled.div`
width:60px;
display:flex;
justify-content:flex-end;
`

const LilaBook = styled.div`
display:flex;
font-family:${fonts.bcBook};

`

const LilaExtraLight = styled.div`
display:flex;
font-family:${fonts.bcExtraLight};

`

const LilaSemiBold = styled.div`
display:flex;
font-family:${fonts.bcSemiBold};

`



const LilaArrow = styled.div`
width:45px;
display:flex;
justify-content:flex-end;
`

const LilaDiscount = styled.div`
width:90px;
display:flex;
justify-content:flex-end;
`


const LilaDeliveryCart = styled.div`
justify-content:flex-start !important;
position:relative;

margin-top:1.9rem;
padding-left:1rem;
height:38px;
display:flex;
align-items:center;
background:var(--bw);
font-family:${fonts.bcHairLine};
font-size:.87rem;

`

const LilaDelivery = styled.div`
justify-content:flex-start !important;
position:relative;

margin-top:1.9rem;
padding-left:3rem;
height:38px;
display:flex;
align-items:center;
background:var(--bw);
font-family:${fonts.bcHairLine};
font-size:.87rem;


  label {

    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
    }
    &:before {
      left: 0;
      width: 16px;
      height: 16px;
      margin: -8.5px 1rem 0;
      background: var(--bg);
      box-shadow: 0 0 1px grey;
    }
    &:after {
      left: 5px;
      width: 6px;
      height: 6px;
      margin: -3.5px 1rem 0;
      opacity: 0;
      background: var(--hase);
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
      }
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

`
const LilaExpressDelivery = styled.div`
justify-content:flex-start !important;
position:relative;
border-top:1px solid var(--bg);
padding-left:3rem;
height:38px;
display:flex;
align-items:center;
background:var(--bw);
font-family:${fonts.bcHairLine};
font-size:.87rem;


  label {

    cursor: pointer;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
    }
    &:before {
      left: 0;
      width: 16px;
      height: 16px;
      margin: -8.5px 1rem 0;
      background: var(--bg);
      box-shadow: 0 0 1px grey;
    }
    &:after {
      left: 5px;
      width: 6px;
      height: 6px;
      margin: -3.5px 1rem 0;
      opacity: 0;
      background: var(--hase);
      transform: translate3d(-40px, 0, 0) scale(0.5);
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: -9999px;
    visibility: hidden;

    &:checked + label {
      &:before {
      }
      &:after {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }
    }
  }

`

const LilaExpressDeliveryCart = styled.div`
justify-content:flex-start !important;
position:relative;
border-top:1px solid var(--bg);
padding-left:1rem;
height:38px;
display:flex;
align-items:center;
background:var(--border);
font-family:${fonts.bcHairLine};
font-size:.87rem;

`

const Express = styled.div`
text-align:center;
margin-top:3.2rem;
margin-bottom:0.9rem;
display:block !important;
font-size:0.97rem;
font-family:${fonts.bcExtraLight};
`

const LilaLine = styled.div`
font-family:${fonts.bcExtraLight};
display:flex;
margin-top:1.2rem;
:before, :after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid var(--border);
            margin: auto;
        }

`
const LilaOther = styled.div`
margin-left:1.2rem;
margin-right:1.2rem;
`

const LilaProceedFade = styled(Ripples)`
font-family:${fonts.bcExtraLight};

letter-spacing:1px;
z-index:999999999;

           // border-bottom: 1px solid var(--txt);
  margin-top:1.8rem;
  border-radius:18px
            //border-top: 1px solid ;
background:none;
            width:250px;

            height:35px;
            color:${colors.fontDark};



`
const LilaProceed = styled(Ripples)`



`

const LilaRipples = styled(LocalizedLink)`
background:var(--haase);
border-radius:18px;
letter-spacing:1px;
           // border-bottom: 1px solid var(--txt);
            //border-top: 1px solid ;
background:var(--haase);
            width:250px;

            height:35px;
            color:${colors.fontDark};
display:flex;
justify-content:center;
align-items:center;
`


const LilaTotalDiv = styled.div`
width:82px;
`

const LilAmount = styled.div`
display:flex;
width:36px;
justify-content:flex-end;

`
const LilacAmount = styled.div`
display:flex;
width:36px;
justify-content:flex-end;
color:var(--hase);

`


const LilaViewCart = styled.div`
 font-size:0.7rem !important;
color:var(--txt) !important;
margin-left:4.3rem;
`


const LilaViewCart_2 = styled.div`
 font-size:0.7rem !important;
color:var(--txt) !important;
`


const LilaMinus = styled.div`
color:var(--hase);

`

const LilaCheckout = styled.div`
display:flex;
justify-content:center;
align-items:center;
`

const StyledSelect = styled(Select)`

width:100%;
height:56px;
letter-spacing:0px;

      font-size:calc(18px + (18 - 18) * ((100vw - 300px) / (1600 - 300)));

.css-26l3qy-menu{
border-radius:1px;
}



.css-cya496-control{

min-height:56px;
}
`

const LilaContact = styled.div`
font-family:${fonts.bcHairLine};
margin-top:2.2rem;
margin-bottom:1.1rem;

`


class MobileOverview extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
gutschriftOpen:false,
      express:false,
      shippingSolutions:colourOptions,
      selectedSolution:colourOptions[1],
      mount:false
    }
    this.openPopup=this.openPopup.bind(this);
    this.closePopup=this.closePopup.bind(this);
    this.handleShippingSelection=this.handleShippingSelection.bind(this)
    this.setLilaExpressRates=this.setLilaExpressRates.bind(this)

  }

  handleShippingSelection (data, region, selection, num, set) {
    if(num===1){
      if(region===1) {
        set({
          _lila_shipping_solution: standard_shipping_de[selection.value],


        })
      }
      else {
        set({
          _lila_shipping_solution: standard_shipping_gb[selection.value],

        })
      }
    }
    if(num===2){
      if(region===1) {
        set({
          _lila_shipping_solution: express_shipping_de[selection.value],

        })
      }
      else {
        set({
          _lila_shipping_solution: express_shipping_gb[selection.value],


        })
      }
    }

    this.setLilaExpressRates(data._lila_express, data, set, lilaRegions[data._lila_region].iso, selection.code)

  }
  openPopup(){
    this.setState({
      gutschriftOpen:true
    })
  }
  closePopup(){
    this.setState({
      gutschriftOpen:false
    })
  }

  handleExpress(data, express, region, set){
    set({_lila_express:express})
    if(express && region=== 1){

      set({
        _lila_shipping_options:express_shipping_de,
        _lila_shipping_solution:express_shipping_de[0]

      })
      this.setLilaExpressRates(true, data, set, lilaRegions[data._lila_region].iso,express_shipping_de[0].code)

    }
    if(!express && region===1){

      set({
        _lila_shipping_options:standard_shipping_de,
        _lila_shipping_solution:standard_shipping_de[0]

      })
      this.setLilaExpressRates(false, data, set, lilaRegions[data._lila_region].iso,standard_shipping_de[0].code)

    }
    if(express && region!=1){

      set({
        _lila_shipping_options:express_shipping_gb,
        _lila_shipping_solution:express_shipping_gb[0]

      })
      this.setLilaExpressRates(true, data, set, lilaRegions[data._lila_region].iso,express_shipping_gb[0].code)

    }
    if(!express && region!=1){

      set({
        _lila_shipping_options:standard_shipping_gb,
        _lila_shipping_solution:standard_shipping_gb[0]

      })
      this.setLilaExpressRates(false, data, set, lilaRegions[data._lila_region].iso,standard_shipping_gb[0].code)

    }

    //this.setLilaExpressRates(express, data, set, lilaRegions[data._lila_region].iso, data._lila_shipping_solution.code)


  }

  setLilaExpressRates = (express, data, set, iso, so) => {
    console.log(so)
    let count = 0;
    for(let n in data.cartItems){
      count+=data.cartItems[n].quantity
    }
    let rc =2;

    if(count>10){
      rc=1
    }
    if(count>20){
      rc=2
    }
    if(count<=10){
      rc=0
    }
    // let rateObj;
    if(express) {
      console.log('express')
      if (so === 'dhlExp') {
        for (let id in dhlExpress_eu) {
          if (iso === dhlExpress_eu[id].id) {
            let rateObj = dhlExpress_eu[id]
            console.log('hello from here')
            console.log(rateObj);
            set({ shipping:rateObj.rates[rc] ,_lila_exp_rates:rateObj, })

          }
        }
      }
      if (so === 'dpdExp') {
        for (let id in dpdExpress_eu) {
          if (iso === dpdExpress_eu[id].id) {
            let rateObj = dpdExpress_eu[id]
            set({ shipping:rateObj.rates[rc] ,_lila_exp_rates:rateObj, })

          }
        }
      }
      if (so === 'upsExp') {
        for (let id in upsExpress_eu) {
          if (iso === upsExpress_eu[id].id) {
            let rateObj = upsExpress_eu[id]
            set({ shipping:rateObj.rates[rc] ,_lila_exp_rates:rateObj, })

          }
        }
      }
      if (so === 'glsExp') {
        for (let id in glsExpress_eu) {
          if (iso === glsExpress_eu[id].id) {
            let rateObj = glsExpress_eu[id]
            set({ shipping:rateObj.rates[rc] ,_lila_exp_rates:rateObj, })
          }
        }
      }
      if (so === 'fedexExp') {
        for (let id in fedexExpress_eu) {
          if (iso === fedexExpress_eu[id].id) {
            let rateObj = fedexExpress_eu[id]
            set({ shipping:rateObj.rates[rc] ,_lila_exp_rates:rateObj, })


          }
        }
      }
    }
    else {
      set({shipping:0})
    }








  }


  componentDidMount() {

  }

  render() {

    return (

      <ContextConsumer>
        {({ data, set }) => (
<div>
          <LilaMobileOverview >
            <LilaViewCart_2>
              <LocalizedLink to={'/cart'}>
                <FormattedMessage id={'cart_0'}/></LocalizedLink>
            </LilaViewCart_2>
            <OverviewHeader>
              <LilaEntry> overview</LilaEntry>
            </OverviewHeader>

            <LilaFoot>

              <Div>
                <LilaSubTotal>
                  <LilaTotalDiv>Subtotal: </LilaTotalDiv><LilAmount>{data.total}€</LilAmount>
                  <LilaViewCart>

                  </LilaViewCart>
                </LilaSubTotal>
                <LilaShipping>
                  <LilaTotalDiv><FormattedMessage id={'shipping'}/>: </LilaTotalDiv><LilAmount>{data.shipping}€</LilAmount>
                  <LilaViewCart>

                    <div>{data._lila_shipping_solution.title}</div>
                  </LilaViewCart>
                </LilaShipping>
                <LilaGutschrift><LilaTotalDiv><FormattedMessage id={'cart_5'}/></LilaTotalDiv><LilacAmount><LilaMinus>-</LilaMinus>{data.discount}€</LilacAmount><LilaGutschriftDetails onClick={()=>this.openPopup()}><StyledLilaEye><LilaEye/></StyledLilaEye></LilaGutschriftDetails></LilaGutschrift>

                <LilaTotal>
                  <LilaTotalDiv>Total:</LilaTotalDiv> <LilAmount>{data.total-data.discount+data.shipping}€</LilAmount>
                  <LilaTaxInfo>
                    <LilaInclusive><FormattedMessage id={'cart_3'}></FormattedMessage>
                    </LilaInclusive>

                  </LilaTaxInfo>
                </LilaTotal>
                {!this.props.checkout &&
                  <div>
                    <form>
                    <LilaDelivery onClick={()=>this.handleExpress(data,false, data._lila_region,set)}>

                      <div className="styled-input-single">
                        <input checked={!data._lila_express}  type="radio" name="fieldset-1" id="radio-example-four"/>
                        <label htmlFor="radio-example-four"><FormattedMessage id={'delivery_3'}/></label>
                      </div>

                      </LilaDelivery>
                    <LilaExpressDelivery onClick={()=>this.handleExpress(data,true, data._lila_region,set)}>

                      <div className="styled-input-single">
                        <input checked={data._lila_express}  type="radio" name="fieldset-1" id="radio-example-five"/>
                        <label htmlFor="radio-example-five"><FormattedMessage id={'delivery_4'}/></label>
                      </div>
                    </LilaExpressDelivery>
                    </form>
                <LilaContact><FormattedMessage id={'shipping_01'}/></LilaContact>
                    <StyledSelect
                      name="styles[]"
                      required={true}
                      closeMenuOnSelect={true}
                      menuPlacement="top"
                      placeholder={<FormattedMessage id="pm_0"/>}
                      options={data._lila_shipping_options}
                      styles={colourStyles}
                      components={{ DropdownIndicator }}
                      value={data._lila_shipping_solution}
                      onChange={(sel)=>this.handleShippingSelection(data, data._lila_region, sel, !data._lila_express?1:2, set)}
                    ></StyledSelect>
                  </div>
                }

              </Div>

              {this.props.checkout &&
<div>

              <LilaDeliveryCart><FormattedMessage id={'delivery_1'}/></LilaDeliveryCart>
              <LilaExpressDeliveryCart><FormattedMessage id={'delivery_2'}/></LilaExpressDeliveryCart>

              <Express>express checkout</Express>


              <LilaExpress>
                <PaypalExpress amount={data.total+data.shipping-data.discount} data={data}/>
              </LilaExpress>

              <LilaLine><LilaOther><FormattedMessage id={'cart_4'}/></LilaOther></LilaLine>
          <LilaCheckout>  <LilaProceedFade  ><Fade bottom spy={this.state.cartOpen}><LilaProceed ><LilaRipples to={'/checkout'} ><FormattedMessage id={'cart_2'}></FormattedMessage></LilaRipples></LilaProceed></Fade></LilaProceedFade></LilaCheckout>
</div>}
            </LilaFoot>



          </LilaMobileOverview>
  <LilaPopup>
    <StyledPopup
      open={this.state.gutschriftOpen}
      closeOnDocumentClick
      onClose={this.closePopup}
    >
      <div className="modal">
        <a className="close" onClick={this.closePopup}>

          <PopIcon className={'close-icon collapsed'}></PopIcon>
        </a>



        <LilaHase_2>
          <Lh_2>
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
              <defs>
              </defs>
              <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
              <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
              <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
              <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                    transform="translate(-14.76 -19.7)"/>
              <path className="cls-1"
                    d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                    transform="translate(-14.76 -19.7)"/>
              <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                    transform="translate(-14.76 -19.7)"/>
              <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
              <polygon className="cls-3"
                       points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
              <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
              <polygon className="cls-3"
                       points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
            </svg>
          </Lh_2>
          <LilaSymbol>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSymbol>
        </LilaHase_2>
        <Gutschrift>Gutschrift*</Gutschrift>

        <LilaRowsWrap><Fade top spy={this.state.gutschriftOpen}> <GutschriftSub>ab einem Bestellwert von: </GutschriftSub></Fade>


          <LilaRows>
            <LilaHairline> <LilaEuro>60€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>5€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaHairline>
            <LilaExtraLight> <LilaEuro>100€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>10€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaExtraLight>
            <LilaBook> <LilaEuro>200€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>20€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaBook>
            <LilaSemiBold> <LilaEuro>500€</LilaEuro> <LilaArrow>-></LilaArrow> <LilaEuro_2>50€</LilaEuro_2> <LilaDiscount>Gutschrift</LilaDiscount></LilaSemiBold>

          </LilaRows></LilaRowsWrap>
        <LilaRowsWrap_2>
          <GutschriftSub_2>*gültig für jede Bestellung.</GutschriftSub_2>
        </LilaRowsWrap_2>


      </div>     </StyledPopup></LilaPopup>
</div>
        )}
      </ContextConsumer>
    )
  }
}




export default MobileOverview


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
