import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

const regionOptions = [
  { id:0, value: 'be',iso:'be', color:'darkgrey', label: <FormattedMessage id={'region_1'}/>},
  { id:1, value: 'de',iso:'de' ,color:'darkgrey', label: <FormattedMessage id={'region_2'}/> },
  { id:2, value: 'gb',iso:'gb' ,color:'darkgrey', label: <FormattedMessage id={'region_3'}/> },
  { id:3, value: 'it',iso:'it' ,color:'darkgrey', label: <FormattedMessage id={'region_4'}/> },
  { id:4, value: 'ie',iso:'ie' ,color:'darkgrey', label: <FormattedMessage id={'region_5'}/> },
  { id:5, value: 'nl',iso:'nl' ,color:'darkgrey', label: <FormattedMessage id={'region_6'}/> },
  { id:6, value: 'at',iso:'at' ,color:'darkgrey', label: <FormattedMessage id={'region_7'}/> },
  { id:7, value: 'pt',iso:'pt' ,color:'darkgrey', label: <FormattedMessage id={'region_8'}/> },
  { id:8, value: 'es',iso:'es' ,color:'darkgrey', label: <FormattedMessage id={'region_9'}/> },
  { id:9, value: 'se',iso:'se' ,color:'darkgrey', label: <FormattedMessage id={'region_10'}/> },
  { id:10, value: 'ch',iso:'ch' ,color:'darkgrey', label: <FormattedMessage id={'region_11'}/> },
  { id:11, value: 'cz',iso:'cz' ,color:'darkgrey', label: <FormattedMessage id={'region_12'}/> },
]

import {
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import styled from "@emotion/styled";
import { createPaymentIntent } from '../functions/stripeFunctions'
import {navigate} from "@reach/router";
import {fonts} from "../styles/variables";

import {
  getNewOrderID,
  getQuantity,
  getRequestData,
  readBulkedInventory,
  setBulkedInventory
} from '../functions/stockFunctions'
import { sendAdminOrderSuccess, sendCusOrderSuccess } from '../functions/sesFunctions'






const PayButton = styled.button`
font-family:${fonts.bcExtraLight};
margin-top:1.2rem;
margin-bottom:1.2rem;
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;


:hover{
opacity:1;
text-decoration:underline;
background: var(--haase);


}

`


const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-bottom:2rem;
margin-top:2rem;
margin-left:10%;
display:flex;
justify-content:flex-start;
align-items:center;

`

const LilaSpinner =styled.div`
.cls-1{fill:var(--hase);}

width:64px;




.rotate {
  animation: rotation 1s infinite linear;


}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67) ;
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`

const Wrapper = styled.div`
.StripeElement{
padding-right:0px !important;
padding-left:10px !important;

font-family:${fonts.bcHairLine};


}

.error{
color:var(--txt);
font-family:${fonts.bcHairLine};
}

`




class _CardForm extends Component {
  state = {
    ready:false,
    errorMessage: '',
    amount:this.props.amount,
    showSpinner:false
  };



  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleReady = () => {
    //console.log('[ready]');
    this.setState({
      ready: true,
      showSpinner:false

    });

  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      this.setState({showSpinner: true});

      let stripe_local=this.props.stripe
      //const str_cus_id = JSON.parse(window.localStorage.profile["https://dev-uv101:eu:auth0:com/user_metadata"]).stripe_customer_id;



      //console.log(evt)

      let cData = this.props.data
      let extra = {
        payment_method_types:['giropay'],
        confirmation:false,
        capture_method:'automatic'
      }
      let giropayPayment = createPaymentIntent(cData, extra);
      giropayPayment.then(function(result) {


          //console.log(result.paymentMethod) // Handle result.error or result.token
        stripe_local.confirmGiropayPayment(result.paymentIntent.client_secret, {
          payment_method: {

            billing_details: {
              name: cData._lila_cus_name1 + ' ' + cData._lila_cus_name2,
              email: cData._lila_cus_mail,
              phone: (cData._lila_cus_phone!='')?cData._lila_cus_phone:'-',
              address: {
                city: cData._lila_cus_city,
                country: regionOptions[cData._lila_region].iso,
                line1: cData._lila_cus_street + ' ' + cData._lila_cus_nr.toString(),
                line2: cData._lila_cus_company + ' ' + cData._lila_cus_address2,
                postal_code: cData._lila_cus_code
              }
            },
          },
          return_url: 'https://lilahase.com/order-success',
        }).then(function(res) {
            //console.log(res)

            if(res.paymentIntent.status==='succeeded') {

              // let descr = 'Card: ' + result.paymentMethod.card.brand + ' | PaymentIntent: ' + res.paymentIntent.id
              /*  let reservationID=  postBooking(descr, cus_id, res.paymentIntent.id); // post reservation to cloudbeds (reservation, items, note, payment), description used for paymentNotes
               reservationID.then(function (r) {


                 if (r != null) {
                   //console.log(JSON.parse(r.body))


                 }

               });

               */

              readBulkedInventory(cData.cartItems)
                .then(dynamoData => {
                  // console.log(dynamoData)
                  const requestData = getRequestData( dynamoData.data, getQuantity(cData.cartItems))
                  setBulkedInventory(requestData)
                  //todo: send error email to info@

                })

              const orderID = getNewOrderID()
              sendCusOrderSuccess(cData, orderID)
              sendAdminOrderSuccess(cData, orderID)
              //todo:
              //      backend: error/bounce handling
              //      frontend: show notifcation

              navigate('/order-success')








            }
            else  {
              //todo: handle error -> show error notification
            }


          }.bind(this));

      }.bind(this));




    } else {
      this.setState({showSpinner: false});

      //console.log("Stripe.js hasn't loaded yet or card details incomplete");
    }
  };

  componentDidMount(){


  }

  render() {
    return (
      <div className="CardDemo">
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <LilaSpinner>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSpinner>
        </StyledSpinner >

        <form style={{display:this.state.showSpinner ? 'none':'block'}} onSubmit={this.handleSubmit.bind(this)}>



          <PayButton disabled={!this.props.stripe}> <FormattedMessage id={'pm_3'}/></PayButton>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class LilaGiropay extends Component<any,any> {
  constructor(props) {
    super(props)

    this.state = {
      stripe: null,
      countdown: 3,
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // Remove our existing Stripe script to keep the DOM clean.
    // document.getElementById('stripe-script').remove();
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.id = 'stripe-script';
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const countdown = setInterval(() => {
        this.setState({ countdown: this.state.countdown - 1 });
      }, 1000);
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        clearInterval(countdown);
        this.setState({
          stripe: window.Stripe('pk_live_2i7bcfbOIxbpvAHG1eYFk5ju00HJOnRFxV'),
        });
      }, 1000);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider  stripe={this.state.stripe}>
        <Wrapper>
          <Elements>
            <CardForm
              amount={this.props.amount}
              countdown={this.state.countdown}
              handleResult={this.props.handleResult}
              data={this.props.data}
            />
          </Elements>
        </Wrapper>
      </StripeProvider>
    );
  }
}
