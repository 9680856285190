import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from '@emotion/styled'
import CheckoutHeader from '../components/checkout/CheckoutHeader'
import CheckoutForm from '../components/checkout/CheckoutForm'


const LilaCheckoutWrap = styled.div`
padding-top:10rem;
`
const Cart = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <Page>
      <Container>
        <CheckoutHeader/>
<LilaCheckoutWrap>
<CheckoutForm/>
</LilaCheckoutWrap>

      </Container>
    </Page>
  </IndexLayout>
)

export default Cart
