import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

const CART_ELEMENT_OPTIONS = {
  // Custom styling can be passed to options when creating an Element
  style: {
    base: {
      padding: '10px 12px',
      color: '#aab7c4',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
    },
  },
};

function CardSection() {
  return (
    <label>
      <CardElement options={CART_ELEMENT_OPTIONS} />
    </label>
  );
};

export default CardSection;
