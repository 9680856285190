import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';


import {
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import styled from "@emotion/styled";
//import {createCreditCardPayment} from "../functions/stripeFunctions";
import {navigate} from "@reach/router";
import {fonts} from "../styles/variables";
//import TextField from '@material-ui/core/TextField'
//import Input from '@material-ui/core/Input'





const PayButton = styled.button`
font-family:${fonts.bcExtraLight};
margin-top:1.2rem;
margin-bottom:1.2rem;
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;


:hover{
opacity:1;
text-decoration:underline;
background: var(--haase);


}

`


const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-bottom:2rem;
margin-top:2rem;
margin-left:10%;
display:flex;
justify-content:flex-start;
align-items:center;

`

const LilaSpinner =styled.div`
.cls-1{fill:var(--hase);}

width:64px;




.rotate {
  animation: rotation 1s infinite linear;


}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67) ;
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`

const Wrapper = styled.div`
.StripeElement{
padding-right:0px !important;
padding-left:10px !important;

font-family:${fonts.bcHairLine};


}

.error{
color:var(--txt);
font-family:${fonts.bcHairLine};
}

`


const LilaTitle = styled.div`
font-family:${fonts.bcHairLine};
margin-top:0.7rem;
margin-bottom:1.1rem;
display:flex;
align-items:center;

`





class _CardForm extends Component {
  state = {
    ready:false,
    errorMessage: '',
    amount:this.props.amount,
    showSpinner:false
  };



  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleReady = () => {
    //console.log('[ready]');
    this.setState({
      ready: true,
      showSpinner:false

    });

  };

  handleSubmit = (evt) => {
    this.setState({
      showSpinner:true
    })
    evt.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createSource({
        type: 'klarna',
        amount: 816,
        currency: 'eur',
        klarna: {
          product: 'payment',
          purchase_country: 'DE',
        },
        source_order: {
          items: [{
            type: 'sku',
            description: 'Grey cotton T-shirt',
            quantity: 2,
            currency: 'eur',
            amount: 796,
          }, {
            type: 'tax',
            description: 'Taxes',
            currency: 'eur',
            amount: 20,
          }, {
            type: 'shipping',
            description: 'Free Shipping',
            currency: 'eur',
            amount: 0,
          }],
        },
      }).then((source) => {
        console.log(source)
        navigate(source.source.klarna.pay_now_redirect_url)


      })
        .catch((err) => { // Error response
          console.log(err);

        })


    } else {
      //console.log("Stripe.js hasn't loaded yet.");
    }

  };

  componentDidMount(){

    if(!this.state.ready){
      this.setState({showSpinner: true});

    }
  }

  render() {
    return (
      <div className="CardDemo">
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <LilaSpinner>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSpinner>
        </StyledSpinner >

        <div style={{display:this.state.showSpinner ? 'none':'block'}} onSubmit={this.handleSubmit.bind(this)}>
          <LilaTitle><FormattedMessage id={'pm_5'}/></LilaTitle>

          <br/>

klsdmlökm
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <br/>

          <PayButton disabled={!this.props.stripe}> <FormattedMessage id={'pm_3'}/></PayButton>
        </div>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class LilaKlarna extends Component<any,any> {
  constructor(props) {
    super(props)

    this.state = {
      stripe: null,
      countdown: 3,
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // Remove our existing Stripe script to keep the DOM clean.
    // document.getElementById('stripe-script').remove();
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.id = 'stripe-script';
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const countdown = setInterval(() => {
        this.setState({ countdown: this.state.countdown - 1 });
      }, 1000);
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        clearInterval(countdown);
        this.setState({
          stripe: window.Stripe('pk_live_2i7bcfbOIxbpvAHG1eYFk5ju00HJOnRFxV'),
        });
      }, 3000);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider postBooking={this.props.postBooking} stripe={this.state.stripe}>
        <Wrapper>
          <Elements>
            <CardForm
              postBooking={this.props.postBooking}
              amount={this.props.amount}
              countdown={this.state.countdown}
              handleResult={this.props.handleResult}
              data={this.props.data}
            />
          </Elements>
        </Wrapper>
      </StripeProvider>
    );
  }
}
