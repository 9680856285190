import React from 'react';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'
import {navigate} from "@reach/router";

import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';
import { fonts } from '../styles/variables'
import { createPaymentIntent } from '../functions/stripeFunctions'
import {
  getNewOrderID,
  getQuantity,
  getRequestData,
  readBulkedInventory,
  setBulkedInventory
} from '../functions/stockFunctions'
import CardSection from './CardSection'
import { sendAdminOrderSuccess, sendCusOrderSuccess } from '../functions/sesFunctions'


const regionOptions = [
  { id:0, value: 'be',iso:'be', color:'darkgrey', label: <FormattedMessage id={'region_1'}/>},
  { id:1, value: 'de',iso:'de' ,color:'darkgrey', label: <FormattedMessage id={'region_2'}/> },
  { id:2, value: 'gb',iso:'gb' ,color:'darkgrey', label: <FormattedMessage id={'region_3'}/> },
  { id:3, value: 'it',iso:'it' ,color:'darkgrey', label: <FormattedMessage id={'region_4'}/> },
  { id:4, value: 'ie',iso:'ie' ,color:'darkgrey', label: <FormattedMessage id={'region_5'}/> },
  { id:5, value: 'nl',iso:'nl' ,color:'darkgrey', label: <FormattedMessage id={'region_6'}/> },
  { id:6, value: 'at',iso:'at' ,color:'darkgrey', label: <FormattedMessage id={'region_7'}/> },
  { id:7, value: 'pt',iso:'pt' ,color:'darkgrey', label: <FormattedMessage id={'region_8'}/> },
  { id:8, value: 'es',iso:'es' ,color:'darkgrey', label: <FormattedMessage id={'region_9'}/> },
  { id:9, value: 'se',iso:'se' ,color:'darkgrey', label: <FormattedMessage id={'region_10'}/> },
  { id:10, value: 'ch',iso:'ch' ,color:'darkgrey', label: <FormattedMessage id={'region_11'}/> },
  { id:11, value: 'cz',iso:'cz' ,color:'darkgrey', label: <FormattedMessage id={'region_12'}/> },
]


const LilaPay = styled.button`
font-family:${fonts.bcExtraLight};
margin-top:3.2rem;
margin-bottom:7.2rem;
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;


:hover{
opacity:1;
text-decoration:underline;
background: var(--haase);


}

`

const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-bottom:2rem;
margin-top:2rem;
margin-left:10%;
display:flex;
justify-content:flex-start;
align-items:center;

`

const LilaSpinner =styled.div`
.cls-1{fill:var(--hase);}

width:64px;




.rotate {
  animation: rotation 1s infinite linear;


}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67) ;
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`


class CardGate extends React.Component {
  constructor(props){
    super(props)

    this.state={
      showSpinner:false
    }
  }
  handleSubmit = async (event) => {
    this.setState({showSpinner:true})
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }


    // For brevity, this example is using uncontrolled components for
    // the accountholder's name. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components


    let cData = this.props.data
    let extra = {
      payment_method_types:['card'],
      confirmation:false,
      capture_method:'automatic'
    }
    let idealPayment =  createPaymentIntent(cData, extra);
    idealPayment.then(function(pm) {

      console.log(pm.paymentIntent)
      stripe.confirmCardPayment(pm.paymentIntent.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: cData._lila_cus_name1 + ' ' + cData._lila_cus_name2,
            email: cData._lila_cus_mail,
            phone: (cData._lila_cus_phone!='')?cData._lila_cus_phone:'-',
            address: {
              city: cData._lila_cus_city,
              country: regionOptions[cData._lila_region].iso,
              line1: cData._lila_cus_street + ' ' + cData._lila_cus_nr.toString(),
              line2: cData._lila_cus_company + ' ' + cData._lila_cus_address2,
              postal_code: cData._lila_cus_code
            }
          },
        }
      }).then(function(result) {
        if (result.paymentIntent.status==='succeeded') {
          // Show error to your customer.
          console.log(result);
          readBulkedInventory(cData.cartItems)
            .then(dynamoData => {
              // console.log(dynamoData)
              const requestData = getRequestData( dynamoData.data, getQuantity(cData.cartItems))
              setBulkedInventory(requestData)
              //todo: send error email to info@

            })

          const orderID = getNewOrderID()
          sendCusOrderSuccess(cData, orderID)
          sendAdminOrderSuccess(cData, orderID)
          //todo:
          //      backend: error/bounce handling
          //      frontend: show notifcation

          navigate('/order-success')
        }

      });

  });
  }

  render() {
    const {stripe} = this.props;

    return (
      <div>
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <LilaSpinner>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSpinner>
        </StyledSpinner >
      <form style={{display:(this.state.showSpinner) ? 'none':'block'}} onSubmit={this.handleSubmit}>
        <div class="form-row">

        </div>
        <div class="form-row">
          <CardSection />
        </div>
        <LilaPay type="submit" disabled={!stripe}>
          Submit Payment
        </LilaPay>
      </form>
      </div>
    );
  }
}

export default class InjectedCardGate extends React.Component {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CardGate data={this.props.data} stripe={stripe} elements={elements}/>
        )}
      </ElementsConsumer>
    );
  }
}
