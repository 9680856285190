import * as React from 'react'
import { ElementConfig, useEffect } from 'react'
import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import { fonts } from '../../styles/variables'
import { FormattedMessage } from 'react-intl'
import Fade from 'react-reveal/Fade'
import { SlideDown } from 'react-slidedown'
import Select, { components } from 'react-select'
import { BsChevronCompactDown } from 'react-icons/bs'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { store } from 'react-notifications-component'


import chroma from 'chroma-js'
import MobileOverview from '../cart/MobileOverview'
import {
  dhlExpress_eu,
  dpdExpress_eu,
  fedexExpress_eu,
  glsExpress_eu,
  standard_shipping_de,
  standard_shipping_gb,
  upsExpress_eu
} from '../../constants/shippingOptions'
import ContextConsumer from '../Context'
import { standard_pm_methods } from '../../constants/paymentMethods'
import Popup from 'reactjs-popup'
import InjectedIdealGate from '../../gateways/IdealGate'
import CryptoGate from '../../gateways/CryptoGate'
import { LilaGiropay } from '../../gateways/GiropayGate'
import { LilaBankTransfer } from '../../gateways/BankTransferGate'
import PaypalExpress from '../../gateways/PaypalExpress'

import StockProvider from '../StockProvider'
import InjectedCardGate from '../../gateways/CreditGate'
import { getItemCount } from '../../functions/stripeFunctions'
import { navigate } from 'gatsby'
import { lilaRegions } from '../../constants/lilaRegions'
import { LilaKlarna } from '../../gateways/KlarnaGate'

//todo: STRIPE PUBLIC switch test/live key
const stripePromise = loadStripe("pk_live_2i7bcfbOIxbpvAHG1eYFk5ju00HJOnRFxV");


const LilaHase_2 = styled.div`
width:100%;
display:flex;
align-items:center;
height:50px;




`

const Lh_2 = styled.div`
width:196px;

.cls-1{fill:var(--hase);}.cls-2{fill:none;}.cls-3{fill:var(--hase);}
`



const PopIcon = styled.div`
width:10px;

text-align:center;
color:var(--txt);

`

const StyledPopup:any = styled(Popup)`

`

const LilaPopup = styled.div`

.CardField CardField--ltr{
padding-left:5px;
}
.InputElement {
color:var(--txt) !important;
}


.ElementsApp {
color:var(--txt) !important;
}

position:absolute;
z-index:999999999;
.modal > .close {

  cursor: pointer;
  position: absolute;
  display: flex;
  line-height: 20px;
  right: 14px;
  top: 14px;
  font-size: 24px;
  background: var(--border);
  border-radius: 50%;
    border: 1px solid var(--background);
    z-index:999999999  !important;
width:36px;
height:36px;
justify-content:center;
align-items:center;

}

button {

}

form {
color:var(--txt) !important;
margin-top:40px;
  padding-bottom: 40px;
  width:400px;
  padding-right:10%;

  @media screen and (max-width:960px){
width:100%;

  }

}

.StripeElement {
padding-left:5px;
padding-left:5px;
  display: block;
  height:56px;
  margin: 0px 0 0px 0px;
  padding: 18px 5px;
  border-radius: 2px;
  background: rgba(0,0,0,.04);
  color:var(--txt) !important;
  font-family:${fonts.bcExtraLight};
    box-shadow: none;

}




input {

}

.StripeElement--focus {
  box-shadow: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
    color:var(--txt);
    border-bottom: 2px solid var(--hase);

}

.modal {

min-height:200px;
overflow-y:scroll;
width:100%;
padding-left:10%;
padding-right: 10%;
padding-top:76px;
  @media screen and (max-width:960px){
  width:100%;
  }
}

.popup-content {
background:var(--bg) !important;

display:flex;
justify-content:center;
align-items:center;
border:none !important;
padding:0px !important;
border-radius:2px;

@media screen and (max-width:760px){
width:100% !important;
}

}

.popup-overlay{
z-index:999999 !important;
}

.CardDemo{
  @media screen and (max-width:960px){
  width:100% !important;
  }
}

.close-icon {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(135deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
}





.Mui-error{
}
.MuiFilledInput-root{
background:none !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border);
  -webkit-text-fill-color: var(--txt);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.input:-internal-autofill-selected {

background:var(--bg) !important;
color:var(--txt) !important;

}

.MuiFormControl-root {
width:100%;
}

.MuiInputBase-root{
color:var(--txt) !important;
opacity:1;
font-family:${fonts.bcBook};
//width:390px;
margin-right:5px;

 @media screen and (max-width: 934px) {
}

  @media screen and (max-width:960px){
width:100%;
  }
}

.MuiFormControl-root MuiTextField-root{
color:var(--txt) !important;
background: rgba(0,0,0,0.00); !important;
}
.MuiFormLabel-root{
font-family:${fonts.bcLight};
opacity:.5;
color:var(--txt) !important;

}

.MuiFormLabel-root.Mui-focused{


color:var(--hase) !important;


}
.MuiInputBase-input{
background-color: rgba(0,0,0,0.03);

:hover{
background:rgba(0,0,0,.06)
}

:focus{
color:var(--txt) !important;
}
}

.MuiFilledInput-underline {
::after{
border-bottom-color:var(--hase) !important;
}

::before{
 border-bottom-color:var(--border) !important;

}
}





.MuiInputBase-input MuiFilledInput-input{
color:var(--txt);
background: rgba(0,0,0,0.00) !important;
}



`







const LilaArrowWrap = styled.div`
width:54px;
display:flex;
justify-content:center;
align-items:center;
`





const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
    <LilaArrowWrap><BsChevronCompactDown /></LilaArrowWrap>

    </components.DropdownIndicator>
  );
};

const colourStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      marginBottom: '18px',
      marginTop:'18px',
      opacity:!state.isFocused ? .2: 1,
      color:'var(--txt)'

    };
  },

  control: (styles, state) => ({
    ...styles,
    '&::after': { borderColor: 'var(--hase)' },
    '&:hover': { borderColor: 'none', backgroundColor:'rgba(0, 0, 0, 0.06)' },
    borderColor:!state.isFocused ?'var(--border)':'var(--hase)',
    boxShadow: 'none',
    borderWidth:!state.isFocused ?'1px':'2px',
    borderTop:!state.isFocused ?'1px':'0px',
    borderLeft:!state.isFocused ?'1px':'0px',
    borderRight:!state.isFocused ?'1px':'0px',
    background:'rgba(0, 0, 0, 0.03)',
    color:'var(--txt) !important',
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px',
    borderBottomRightRadius:'0px',
    borderBottomLeftRadius:'0px',
    height:'56px',}),
  singleValue: styles => ({ ...styles, backgroundColor: 'none',

    color:'var(--txt) !important' }),
  placeholder: styles => ({ ...styles, fontFamily: fonts.bcHairLine,

    color:'var(--txt) !important' }),
  menu: styles => ({ ...styles,  zIndex: 999999999999}),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      zIndex: 999999999999,
      backgroundColor: isDisabled
        ? 'var(--bg)'
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : 'var(--selectBg)',
      color: isDisabled
        ? 'var(--txt)'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

const regionSelectStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      marginBottom: '18px',
      marginTop:'18px',
      opacity:!state.isFocused ? .2: 1,
      color:'var(--txt)'

    };
  },

  control: (styles, state) => ({
    ...styles,
    '&::after': { borderColor: 'var(--hase)' },
    '&:hover': { borderColor: 'none', backgroundColor:'rgba(0, 0, 0, 0.06)' },
    borderColor:!state.isFocused ?'var(--border)':'var(--hase)',
    boxShadow: 'none',
    borderWidth:!state.isFocused ?'1px':'2px',
    borderTop:!state.isFocused ?'1px':'0px',
    borderLeft:!state.isFocused ?'1px':'0px',
    borderRight:!state.isFocused ?'1px':'0px',
    background:'rgba(0, 0, 0, 0.03)',
    color:'var(--txt) !important',
    borderTopLeftRadius:'4px',
    borderTopRightRadius:'4px',
    borderBottomRightRadius:'0px',
    borderBottomLeftRadius:'0px',
    height:'56px'}),
  singleValue: styles => ({ ...styles, backgroundColor: 'none',

    color:'var(--txt) !important' }),
  placeholder: styles => ({ ...styles, fontFamily: fonts.bcHairLine,

    color:'var(--txt) !important' }),
  menu: styles => ({ ...styles,  zIndex: 999999999999}),


  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,

      backgroundColor: isDisabled
        ? 'var(--bg)'
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : 'var(--selectBg)',
      color: isDisabled
        ? 'var(--txt)'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '',
    },
  },
}));

const LilaCheckout = styled.div`
width:100%;
padding-left: 10%;

 @media screen and (max-width: 1560px) {
  padding-left: 10%;
}


 @media screen and (max-width: 934px) {
padding-left:2rem;
padding-right:2rem;
}


`



const LilaName = styled.div`
width:640px;
display:flex;
justify-content:space-between;

margin-bottom:1.2rem;

 @media screen and (max-width: 1199px) {
 width:100%;
flex-direction:column;}
`
const LilaCheckoutBottom = styled.div`
width:640px;
display:flex;
justify-content:space-between;
margin-bottom:1.2rem;
margin-top:2.8rem;

 @media screen and (max-width: 1199px) {
width:100%;
justify-content:flex-start;
}

 @media screen and (max-width: 934px) {
width:100%;
justify-content:center;
}

`

const LilaSpace = styled.div`
width:300px;
 @media screen and (max-width: 1199px) {
display:none;
}
 @media screen and (max-width: 934px) {
display:none;
}
`

const LilaPay = styled.button`
font-family:${fonts.bcExtraLight};
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;

:hover{
opacity:1;
text-decoration:underline;
}
`

const LilaSlide = styled.div`
width:640px;
display:flex;
height:80px;
justify-content:space-between;

 @media screen and (max-width: 1199px) {
flex-direction:column;
height:auto;
width:100%;
}
`

const LilaTitle = styled.div`
font-family:${fonts.bcHairLine};
text-decoration:underline;
margin-top:0.7rem;
margin-bottom:1.1rem;
display:flex;
align-items:center;

`


const LilaSlideDown = styled(SlideDown)`
`



const LilaForm = styled.form`
.Mui-error{
}
.MuiFilledInput-root{
background:none !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--border);
  -webkit-text-fill-color: var(--txt);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.input:-internal-autofill-selected {

background:var(--bg) !important;
color:var(--txt) !important;

}



.MuiInputBase-root{
color:var(--txt) !important;
opacity:1;
font-family:${fonts.bcBook};
width:300px;

 @media screen and (max-width: 934px) {
width:100% !important;
}
}

.MuiFormControl-root MuiTextField-root{
color:var(--txt) !important;
background: rgba(0,0,0,0.00); !important;
}
.MuiFormLabel-root{
font-family:${fonts.bcLight};
opacity:.5;
color:var(--txt) !important;

}

.MuiFormLabel-root.Mui-focused{


color:var(--hase) !important;


}
.MuiInputBase-input{
background-color: rgba(0,0,0,0.03);

:hover{
background:rgba(0,0,0,.06)
}

:focus{
color:var(--txt) !important;
}
}

.MuiFilledInput-underline {
::after{
border-bottom-color:var(--hase) !important;
}

::before{
 border-bottom-color:var(--border) !important;

}
}





.MuiInputBase-input MuiFilledInput-input{
color:var(--txt);
background: rgba(0,0,0,0.00) !important;
}
`

const LilaShippingDetails = styled.div`
font-family:${fonts.bcHairLine};
margin-top:2.2rem;
margin-bottom:1.1rem;
display:flex;
align-items:center;
`

const LilaContact = styled.div`
font-family:${fonts.bcHairLine};
margin-top:2.2rem;
margin-bottom:1.1rem;
width:100%;
display:flex;


`



const LilaTab = styled.div`


font-family:${fonts.bcExtraLight};
padding-left:30px;
font-size:0.70rem;
display:flex;
align-items:center;

span{
display:flex;
align-items:center;
}

transition: opacity .4s;
height:2.4rem;
div{
font-size:0.77rem;

color:var(--txt);
}
display:flex;
align-items:center;
.plus-minus-toggle {
display:flex;
align-items:center;
  cursor: pointer;
  height: 1px;
  position: relative;
  width: 7px;
  &:before,
  &:after{
    background: var(--txt);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
    transition: transform 500ms ease;
  }
  &:after {
    transform-origin: center;
  }
  &.collapsed {
    &:after {
      transform: rotate(90deg);
    }
    &:before {
      transform: rotate(180deg);
    }
  }
}
`


const PlusMinus = styled.div`
margin-right:0.3rem;
display:flex;
align-items:center;
height:2.4rem;
`

const LilaPaySelect = styled(Select)`

 @media screen and (max-width: 1199px) {
 width:300px;
}

 @media screen and (max-width: 934px) {
width:100%;
}



width:640px;
height:56px;
letter-spacing:0px;

      font-size:calc(18px + (18 - 18) * ((100vw - 300px) / (1600 - 300)));

.css-26l3qy-menu{
border-radius:1px;
}



.css-cya496-control{

min-height:56px;
}
`

const LilaRegion = styled(Select)`
width:300px;
font-family:${fonts.bcExtraLight};

 @media screen and (max-width: 934px) {
width:100% !important;
}



height:56px;
letter-spacing:0px;

font-size:1rem;
.css-26l3qy-menu{
border-radius:1px;
}



.css-cya496-control{

min-height:56px;
}
`


const LilaTotalDiv = styled.div`
width:82px;

`

const LilaTotal = styled.div`
margin-top:1rem;
margin-bottom:2.2rem;
font-size: 0.75rem;
width:100%;
display:flex;
`

const LilaMobileOverviewWrap=styled.div`
display:none;
 @media screen and (max-width: 934px) {
display:block;
}
`
const Nonvisible = styled.div`
display:none;
`


const LilaInclusive = styled.div`
display:flex;
`

const LilAmount = styled.div`
display:flex;
width:36px;
justify-content:flex-end;

`

const LilaTaxInfo = styled.div`
font-size:0.6rem;
letter-spacing:1px;
opacity:.3;
font-size: 0.65rem;
display:flex;
margin-left:4.3rem;

`

const LilaSub = styled.h1`
font-family:${fonts.bcBook};
font-size:1.2rem;
margin-top:2.2rem;
margin-bottom:2.2rem;
`

const StateToLocal = (props) => {

  useEffect(() => {
if(getItemCount(props.cartItems)<=0){
  navigate('/cart')
  store.addNotification({
    title: 'Ihr Warenkorb ist leer.',
    message: "Bitte wählen Sie mindestens ein Produkt um zum Checkout fortzufahren.",
    type: "info",
    insert: "right",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}
props.set({_lila_express:false})
//props.handleLilaRegion(props.data, false,lilaRegions[props.region], props.set)
    },
    [props.mount, props.cartItems
    ],
  );




  return <Nonvisible/>

}

class CheckoutForm extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
additionalInfoOpen:false,
      addPhoneOpen:false,
      lilaRegion:lilaRegions[1].value,
      _lila_region:lilaRegions[1].value,
      mount:false
    }
    this.openAdditionalInfo=this.openAdditionalInfo.bind(this)
    this.closeAdditionalInfo=this.closeAdditionalInfo.bind(this)
    this.openPhone=this.openPhone.bind(this)
    this.closePhone=this.closePhone.bind(this)
    this.toggleAdditionalInfo=this.toggleAdditionalInfo.bind(this)
    this.handleLilaRegion = this.handleLilaRegion.bind(this)
    this.handleLilaPay = this.handleLilaPay.bind(this)
    this.setLilaExpressRates=this.setLilaExpressRates.bind(this)
    this.openLilaPayModal=this.openLilaPayModal.bind(this)
    this.closeLilaPayModal=this.closeLilaPayModal.bind(this)
  }

  handleLilaPay(selection, set){
    set({_lila_pay:selection.id})

  }

  toggleAdditionalInfo(set,boo){
 set({address2Open:!boo})
  }

  openAdditionalInfo(set){
set({ address2Open:true
    })
  }


  closeAdditionalInfo(set){
    set({
      address2Open:false
    })
  }

  openPhone(){
    this.setState({
      addPhoneOpen:true
    })
  }

  closePhone(){
    this.setState({
      addPhoneOpen:true
    })
  }

  setLilaInput(set, k, v){
  if(k==='mail'){
   set({_lila_cus_mail:v})
  }
  if(k==='name1'){
   set({_lila_cus_name1:v})
  }
  if(k==='name2'){
   set({_lila_cus_name2:v})
  }
  if(k==='company'){
   set({_lila_cus_company:v})
  }
  if(k==='address2'){
   set({_lila_cus_address2:v})
  }
  if(k==='street'){
   set({_lila_cus_street:v})
  }
  if(k==='nr'){
   set({_lila_cus_nr:v})
  }
  if(k==='code'){
   set({_lila_cus_code:v})
  }
  if(k==='phone'){
   set({_lila_cus_phone:v})
  }
  if(k==='city'){
   set({_lila_cus_city:v})
  }
  }

  setLilaExpressRates = (express, data, set, iso, so) => {
let count = 0;
    for(let n in data.cartItems){
      count+=data.cartItems[n].quantity
    }
    let rc =2;

    if(count<=10){
      rc=0
    }
    if(count<=20){
      rc=1
    }
    if(count>20){
      rc=2
    }
    let rateObj;
    if(express) {
      if (so === 'dhlExp') {
        for (let id in dhlExpress_eu) {
          if (iso === dhlExpress_eu[id].id) {
            rateObj = dhlExpress_eu[id]
          }
        }
      }
      if (so === 'dpdExp') {
        for (let id in dpdExpress_eu) {
          if (iso === dpdExpress_eu[id].id) {
            rateObj = dpdExpress_eu[id]
          }
        }
      }
      if (so === 'upsExp') {
        for (let id in upsExpress_eu) {
          if (iso === upsExpress_eu[id].id) {
            rateObj = upsExpress_eu[id]
          }
        }
      }
      if (so === 'glsExp') {
        for (let id in glsExpress_eu) {
          if (iso === glsExpress_eu[id].id) {
            rateObj = glsExpress_eu[id]
          }
        }
      }
      if (so === 'fedexExp') {
        for (let id in fedexExpress_eu) {
          if (iso === fedexExpress_eu[id].id) {
            rateObj = fedexExpress_eu[id]
          }
        }
      }
      set({
        _lila_exp_rates:rateObj,
        shipping:(rateObj!=undefined)?rateObj.rates[rc]:0
      })
    }
    else{
      set({shipping:0})
    }
   // console.log(rateObj.rates[rc])








  }

  handleLilaRegion = (data, express, selection,set) => {
    set({_lila_region:selection.id})

    //set regionRates





    if(selection.value==='de'){
        set({
          _lila_shipping_options:standard_shipping_de,
          _lila_shipping_solution:standard_shipping_de[0],
          _lila_express:false

        })
      this.setLilaExpressRates(false,data,set,selection.iso,standard_shipping_de[0].code)

    }
    if(selection.value!='de'){
      set({
        _lila_shipping_options:standard_shipping_gb,
        _lila_shipping_solution:standard_shipping_gb[0],
        _lila_express:false

      })
      this.setLilaExpressRates(false,data,set,selection.iso,standard_shipping_gb[0].code)

    }



  }


  async openLilaPayModal(event, data){
    //sendOrderSuccess(data)
/*
   readBulkedInventory(data.cartItems)
     .then(dynamoData => {
       console.log(dynamoData)
       const requestData = getRequestData( dynamoData.data, getQuantity(data.cartItems))
       setBulkedInventory(requestData)


     })

 */
    {
     // sendCusOrderSuccess(data, getNewOrderID())
    }
//og(event)
if(data._lila_cus_street!=''&& data._lila_cus_mail!='' && data._lila_cus_name1!=''
  && data._lila_cus_name2!='' && data._lila_cus_code!='' && data._lila_cus_nr && data._lila_cus_city)
    this.setState({
      payModalOpen:true
    })

  }

  closeLilaPayModal(){
    this.setState({
      payModalOpen:false
    })
  }

  componentDidMount(): void {





    this.setState({
      mount:true,
    })
  }


  render() {
    const classes = useStyles;
    return(
      <Elements stripe={stripePromise}>
      <ContextConsumer>
        {({ data, set }) => (
        <LilaCheckout id={'component'}>
          <StockProvider cartItems={data.cartItems} set={set}/>
          <StateToLocal cartItems={data.cartItems} data={data} handleLilaRegion={this.handleLilaRegion} mount={this.state.mount} region={data._lila_region} express={data._lila_express} set={set}/>
<LilaMobileOverviewWrap>
  <MobileOverview checkout={false}></MobileOverview>

</LilaMobileOverviewWrap>
<LilaForm  onSubmit={(e)=>e.preventDefault()} className={classes.root} >
<LilaContact><FormattedMessage id={'checkout_0_1'}/></LilaContact>
 <LilaName> <TextField required type="email" id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_1'}/>}
                       value={data._lila_cus_mail}
                       onChange={(e) => this.setLilaInput(set, 'mail', e.currentTarget.value)} >
    <Input
      required type="email"  />
  </TextField>
 </LilaName>
  <LilaShippingDetails><FormattedMessage id={'checkout_0'}></FormattedMessage> <LilaTab onClick={()=>this.toggleAdditionalInfo(set,data.address2Open)}><PlusMinus > <div className={data.address2Open?'plus-minus-toggle':'plus-minus-toggle collapsed'}></div></PlusMinus>{!data.address2Open?<FormattedMessage id={'checkout_10'}/>:<FormattedMessage id={'checkout_11'}/>}</LilaTab></LilaShippingDetails>
<LilaName>
  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_2'}/>}
             value={data._lila_cus_name1}
             onChange={(e) => this.setLilaInput(set, 'name1', e.currentTarget.value)}
  >
    <Input
      required
/>
  </TextField>  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_3'}/>}
                           value={data._lila_cus_name2}
                           onChange={(e) => this.setLilaInput(set, 'name2', e.currentTarget.value)}
>
    <Input
    />
  </TextField>
 </LilaName>
<LilaSlideDown closed={!data.address2Open}>
  <LilaSlide>
  <TextField id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_3_1'}/>}
             value={data._lila_cus_company}
             onChange={(e) => this.setLilaInput(set, 'company', e.currentTarget.value)}
  >
    <Input
      required
     />
  </TextField>  <TextField id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_3_2'}/>}
                           value={data._lila_cus_address2}
                           onChange={(e) => this.setLilaInput(set, 'address2', e.currentTarget.value)}
  >
    <Input
 />
  </TextField>
 </LilaSlide>
</LilaSlideDown>
  <LilaName>
  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_4'}/>} value={data._lila_cus_street}
             onChange={(e) => this.setLilaInput(set, 'street', e.currentTarget.value)}
  >
    <Input
      required
 />
  </TextField>  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_5'}/>}
                           value={data._lila_cus_nr}
                           onChange={(e) => this.setLilaInput(set, 'nr', e.currentTarget.value)}
  >
    <Input
      required
       />
  </TextField>
 </LilaName>
  <LilaName>
  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_6'}/>}
             value={data._lila_cus_code}
             onChange={(e) => this.setLilaInput(set, 'code', e.currentTarget.value)}
  >
    <Input
 />
  </TextField>  <TextField required id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_7'}/>}
                           value={data._lila_cus_city}
                           onChange={(e) => this.setLilaInput(set, 'city', e.currentTarget.value)}
  >
    <Input
      required />
  </TextField>
 </LilaName>  <LilaName>
  <LilaRegion
    name="styles[]"
    required={true}
    closeMenuOnSelect={true}
    menuPlacement="top"
    placeholder={<FormattedMessage id="pm_0" />}
    options={lilaRegions}
    styles={regionSelectStyles}
    components={{ DropdownIndicator }}
    onChange={(selection)=>this.handleLilaRegion(data, data._lila_express, selection, set)}
    value={lilaRegions[data._lila_region]}
  ></LilaRegion>

  <TextField id="filled-basic" label="Filled" variant="filled"  label={<FormattedMessage id={'checkout_9'}/>}
             value={data._lila_cus_phone}
             onChange={(e) => this.setLilaInput(set, 'phone', e.currentTarget.value)}
  >
    <Fade top spy={this.state.addPhoneOpen}>
    <Input
      /></Fade>
  </TextField>
 </LilaName>
  <LilaContact><FormattedMessage id={'checkout_0_2'}/></LilaContact>


  <LilaPaySelect
    name="styles[]"
    required={true}
    closeMenuOnSelect={true}
    menuPlacement="top"
    placeholder={<FormattedMessage id="pm_0" />}
    options={standard_pm_methods}
    styles={colourStyles}
    value={standard_pm_methods[data._lila_pay]}
    components={{ DropdownIndicator }}
    onChange={(selection)=>this.handleLilaPay(selection, set)}

  ></LilaPaySelect>

  <LilaCheckoutBottom>
    <LilaSpace/>
    <LilaPay type={'submit'} onClick={(event)=>this.openLilaPayModal(event, data)}><FormattedMessage id={'pm_3'}/></LilaPay>
  </LilaCheckoutBottom>
</LilaForm>
          <LilaPopup>
            <StyledPopup
              open={this.state.payModalOpen}
              closeOnDocumentClick
              onClose={this.closeLilaPayModal}
            >
              <div className="modal">
                <a className="close" onClick={this.closeLilaPayModal}>

                  <PopIcon className={'close-icon collapsed'}></PopIcon>
                </a>



                <LilaHase_2>
                  <Lh_2>
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                      <defs>
                      </defs>
                      <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                      <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                      <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                      <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                            transform="translate(-14.76 -19.7)"/>
                      <path className="cls-1"
                            d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                            transform="translate(-14.76 -19.7)"/>
                      <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                            transform="translate(-14.76 -19.7)"/>
                      <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                      <polygon className="cls-3"
                               points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                      <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                      <polygon className="cls-3"
                               points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                    </svg>
                  </Lh_2>
                </LilaHase_2>
                <LilaSub>Checkout</LilaSub>

                <LilaTotal>
                  <LilaTotalDiv>Total:</LilaTotalDiv> <LilAmount>{(data.total-data.discount+data.shipping)}€</LilAmount>
                  <LilaTaxInfo>
                    <LilaInclusive><FormattedMessage id={'cart_3'}></FormattedMessage>
                    </LilaInclusive>

                  </LilaTaxInfo>
                </LilaTotal>
                {standard_pm_methods[data._lila_pay].value==='credit' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_7'}/></LilaTitle>
                    <InjectedCardGate data={data}/>
                  </div>
                }

                {standard_pm_methods[data._lila_pay].value==='iDEAL' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_1'}/></LilaTitle>
                    <InjectedIdealGate data={data}/>
                  </div>
                }
                {standard_pm_methods[data._lila_pay].value==='bitcoin' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_4'}/></LilaTitle>

                    <CryptoGate  data={data}/>

                  </div>
                }

                {standard_pm_methods[data._lila_pay].value==='paypal' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_5'}/></LilaTitle>
                    <PaypalExpress amount={data.total+data.shipping-data.discount} data={data}/>
                  </div>
                }
                {standard_pm_methods[data._lila_pay].value==='klarna' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_2'}/></LilaTitle>
                    <LilaKlarna data={data}/>
                  </div>
                }
                {standard_pm_methods[data._lila_pay].value==='giro' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_6'}/></LilaTitle>
                    <LilaGiropay amount={(data.total+data.shipping-data.discount)*100} data={data}/>
                  </div>
                }
                {standard_pm_methods[data._lila_pay].value==='bank' &&
                  <div>
                <LilaTitle><FormattedMessage id={'lila_pay_8'}/></LilaTitle>
                    <LilaBankTransfer data={data}/>
                  </div>
                }




              </div>     </StyledPopup></LilaPopup>

    </LilaCheckout>


        )}
      </ContextConsumer>
      </Elements>
    )
  }
}



export default CheckoutForm


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
