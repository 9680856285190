import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';


import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import styled from "@emotion/styled";
import { createPaymentIntent } from '../functions/stripeFunctions'
import {navigate} from "@reach/router";
import {fonts} from "../styles/variables";

import {
  getNewOrderID,
  getQuantity,
  getRequestData,
  readBulkedInventory,
  setBulkedInventory
} from '../functions/stockFunctions'
import { sendAdminOrderSuccess, sendCusOrderSuccess } from '../functions/sesFunctions'


const createOptions = () => {
  return {
    style: {
      base: {
        fontFamily:fonts.bcMedium,
        fontWeight:600,
        paddingLeft:'5px',
        fontSize: '16px',
        color:'#aab7c4',
        letterSpacing: '0.025em',
        '::placeholder': {
          fontFamily:fonts.bcMedium,
          fontWeight:600,
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};




const PayButton = styled.button`
font-family:${fonts.bcExtraLight};
margin-top:1.2rem;
margin-bottom:1.2rem;
border:none;
cursor:pointer;
width:250px;
height:35px;
display:flex;
justify-content:center;
align-items:center;
background: var(--haase);
color:white;
  border-radius:18px;
    opacity:1;
transition: opacity .4s ease;


:hover{
opacity:1;
text-decoration:underline;
background: var(--haase);


}

`

const Label = styled.label`
text-align:left !important;
input{
width:100% !important;

font-size:16px !important;
 }
padding-left:6px;
`

const InvisibleLabel = styled(Label)`
display:none;
`

const StyledSpinner = styled.div`
width:100%;
height:100%;
margin-bottom:2rem;
margin-top:2rem;
margin-left:10%;
display:flex;
justify-content:flex-start;
align-items:center;

`

const LilaSpinner =styled.div`
.cls-1{fill:var(--hase);}

width:64px;




.rotate {
  animation: rotation 1s infinite linear;


}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(0.67) ;
  }
  to {
    transform: rotate(359deg) scale(0.67);
  }
}
`

const Wrapper = styled.div`
.StripeElement{
padding-right:0px !important;
padding-left:10px !important;

font-family:${fonts.bcHairLine};


}

.error{
color:var(--txt);
font-family:${fonts.bcHairLine};
}

`




const LilaTitle_2 = styled.div`
font-family:${fonts.bcHairLine};
margin-top:0.7rem;

display:flex;
align-items:center;

`



class _CardForm extends Component {
  state = {
    ready:false,
    errorMessage: '',
    amount:this.props.amount,
    showSpinner:false
  };



  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleReady = () => {
    //console.log('[ready]');
    this.setState({
      ready: true,
      showSpinner:false

    });

  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      this.setState({showSpinner: true});

    //  let stripe_local=this.props.stripe
      //const str_cus_id = JSON.parse(window.localStorage.profile["https://dev-uv101:eu:auth0:com/user_metadata"]).stripe_customer_id;

   //   let amount = this.props.data.total

      //console.log(evt)
      let cData = this.props.data
      let extra = {
        payment_method_types:['card'],
        confirmation:false,
        capture_method:'automatic'
      }
      let bankTransferPayment = createPaymentIntent(cData, extra);
      bankTransferPayment.then(function(pm) {

        let pay =pm.paymentIntent
        if(pay!=undefined) {

          readBulkedInventory(cData.cartItems)
            .then(dynamoData => {
             // console.log(dynamoData)
              const requestData = getRequestData( dynamoData.data, getQuantity(cData.cartItems))
              setBulkedInventory(requestData)
              //todo: send error email to info@q

            })

          const orderID = getNewOrderID()
          sendCusOrderSuccess(cData, orderID)
          sendAdminOrderSuccess(cData, orderID)
          //todo:
          //      backend: error/bounce handling
          //      frontend: show notifcation

          navigate('/order-success')
        }

      }.bind(this));




    } else {
      this.setState({showSpinner: false});

      //console.log("Stripe.js hasn't loaded yet or card details incomplete");
    }
  };

  componentDidMount(){

    if(!this.state.ready){
      this.setState({showSpinner: true});

    }
  }

  render() {
    return (
      <div className="CardDemo">
        <StyledSpinner style={{display:(this.state.showSpinner) ? 'flex':'none'}}>
          <LilaSpinner>
            <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 241.36 179.42">
              <defs>
              </defs>
              <path className="cls-1"
                    d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                    transform="translate(-530.64 -373.08)"/>
              <path className="cls-1"
                    d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                    transform="translate(-530.64 -373.08)"/>
            </svg>
          </LilaSpinner>
        </StyledSpinner >

        <form style={{display:this.state.showSpinner ? 'none':'block'}} onSubmit={this.handleSubmit.bind(this)}>


          <InvisibleLabel>
            <LilaTitle_2><FormattedMessage id={'pm_6'}/></LilaTitle_2>

            {this.props.countdown > 0 ? (
              <span className="async-message" style={{display: this.state.ready ? 'none':'block'  }}>
                Loading Form in {this.props.countdown}...
              </span>
            ) : (
              ''
            )}
            {this.props.stripe ? (
              <CardElement
                onReady={this.handleReady.bind(this)}
                onChange={this.handleChange}
                {...createOptions()}
              />
            ) : (
              <div className="StripeElement loading" />
            )}
          </InvisibleLabel>


          <PayButton disabled={!this.props.stripe}> <FormattedMessage id={'pm_x'}/></PayButton>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class LilaBankTransfer extends Component<any,any> {
  constructor(props) {
    super(props)

    this.state = {
      stripe: null,
      countdown: 3,
    };
  }

  componentDidMount() {
    // componentDidMount only runs in a browser environment.
    // In addition to loading asynchronously, this code is safe to server-side render.

    // Remove our existing Stripe script to keep the DOM clean.
    // document.getElementById('stripe-script').remove();
    // You can inject a script tag manually like this,
    // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
    const stripeJs = document.createElement('script');
    stripeJs.id = 'stripe-script';
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      const countdown = setInterval(() => {
        this.setState({ countdown: this.state.countdown - 1 });
      }, 1000);
      // The setTimeout lets us pretend that Stripe.js took a long time to load
      // Take it out of your production code!
      setTimeout(() => {
        clearInterval(countdown);
        this.setState({
          stripe: window.Stripe('pk_live_2i7bcfbOIxbpvAHG1eYFk5ju00HJOnRFxV'),
        });
      }, 1000);
    };
    document.body && document.body.appendChild(stripeJs);
  }

  render() {
    return (
      <StripeProvider postBooking={this.props.postBooking} stripe={this.state.stripe}>
        <Wrapper>
          <Elements>
            <CardForm
              postBooking={this.props.postBooking}
              amount={this.props.amount}
              countdown={this.state.countdown}
              handleResult={this.props.handleResult}
              data={this.props.data}
            />
          </Elements>
        </Wrapper>
      </StripeProvider>
    );
  }
}
